export const goodAnswer = () => ({
  type: 'GOOD_ANSWER',
});

export const wrongAnswer = () => ({
  type: 'WRONG_ANSWER',
});

export const resetScore = () => ({
  type: 'RESET_SCORE',
});
